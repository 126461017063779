import React from "react"

import Seo from '../components/Seo'
import Tabs from "../components/Tabs"
import TabContent from "../components/TabContent"
import TextContainer from "../components/TextContainer"
import CourseLayout from "../components/CourseLayout"
import TabsContainer from "../components/TabsContainer"

import * as data from "../courseData"

export default function Course() {
  const { title, hours, price, name, id } = data.hr
  return (
    <CourseLayout
      title={title.main}
      subtitle={title.second}
      hours={hours}
      grossPrice={price.gross}
      newGrossPrice={price.new_gross}
      netPrice={price.net}
      name={name}
      id={id}
    >
      <Seo title="Kurs kadry i płace" description={`Kurs 'Kadry i płace' Wrocław od ${price.gross} zł - dla osób, chciałyby pracować w firmach w działach kadrowo-płacowych lub samodzielnie rozliczać pracowników w swojej firm`} />
      <TextContainer>
        <h3>Kurs kadry i płace</h3>
        <p>
          Kurs „Kadry i płace” adresowany jest do osób, chciałyby pracować w
          firmach w działach kadrowo-płacowych lub samodzielnie rozliczać
          pracowników w swojej firmie, a nie posiadają wiedzy oraz doświadczenia
          w tym zakresie.
        </p>
        <h3>Dla kogo?</h3>
        <ul>
          <li>chcesz samodzielnie prowadzić kadry lub płace w firmie</li>
          <li>chcesz znać prawa pracownika wynikające ze stosunku pracy</li>
          <li>chcesz wiedzieć co może od pracownika oczekiwać pracodawca</li>
          <li>
            naliczać wynagrodzenia wynikające ze stosunku pracy oraz stosunków
            cywilnoprawnych
          </li>
          <li>chcesz poznać zasady naliczania urlopu wypoczynkowego</li>
          <li>chcesz znać prawa pracownika związane z rodzicielstwem</li>
          <li>
            poznać zasady przyznawania świadczeń z ubezpieczenia społecznego
          </li>
          <li>
            praktycznie poznać zasady prowadzenia dokumentacji pracowniczej
          </li>
          <li>
            chcesz nauczyć się obsługiwać program kadrowo-płacowy oraz program
            Płatnik
          </li>
        </ul>
        <p>
          Na kursie kadrowo-płacowym uczestnicy szkolenia zdobędą wiedzę z
          zakresu stosowania prawa pracy, będą samodzielnie prowadzić teczki
          pracownicze, sporządzać rzeczywiste dokumenty kadrowe. Posiądą
          umiejętność naliczania wynagrodzeń, poznają zasady podlegania
          ubezpieczeniom społecznym oraz naliczania składek społecznych i
          zdrowotnych, a także zasady opodatkowania ze stosunku pracy i z tytułu
          umów cywilno-prawnych. Po ukończeniu szkolenia uczestnicy będą
          samodzielnie prowadzić pełną dokumentację kadrową i płacową,
          obsługiwać program kadrowo-płacowy oraz ZUSowski program Płatnik.
        </p>

        <h3>
          Program kadrowo-płacowy R2Płatnik oraz Płatnik ZUS – ćwiczenia
          praktyczne
        </h3>
        <p>
          Ponieważ szczególny nacisk kładziemy na praktykę, połowę czasu trwania
          kursu stanowią zajęcia praktyczne polegające na pracy z programem
          kadrowo-płacowym R2Płatnik oraz programem Płatnik używanym do
          rozliczeń z ZUS. Zajęcia komputerowe swym zakresem obejmują założenie
          firmy w programie kadrowo-płacowym, zatrudnienie pracowników na
          podstawie różnych umów, sporządzenie dokumentacji kadrowej,
          naliczaniem wynagrodzeń według różnych wariantów, wynagrodzenia za
          okres choroby, urlopu itp. Dane z programu kadrowo płacowego
          przenoszone są do Płatnika ZUS, w którym stanowią podstawę do
          sporządzenia deklaracji wymaganych przez ZUS. Przy użyciu rzutnika
          prowadzący zajęcia zapoznaje uczestników z obsługą programów oraz
          pokazuje sposób wprowadzania danych oraz sporządzania list płac,
          zestawień i deklaracji. Każdy uczestnik kursu pracuje na osobnym
          komputerze przez cały czas trwania kursu.
        </p>
        <TabsContainer>
          <Tabs elements={["Program kursu", "Harmonogram kursu"]}>
            <TabContent index={0}>
              <h3>Program kursu kadry płace</h3>
              <h4>Prawo pracy</h4>
              <ul>
                <li>Podstawowe zasady prawa pracy</li>
                <li>Stosunek pracy i umowy cywilno prawne</li>
                <li>Obowiązki pracodawcy i pracownika</li>
                <li>
                  Odpowiedzialność za wykroczenia przeciwko prawom pracownika
                </li>
                <li>Wynagrodzenia i jego ochrona</li>
                <li>Czas pracy</li>
                <li>Urlopy pracownicze i inne nieobecności</li>
                <li>Uprawnienia pracowników związane z rodzicielstwem</li>
                <li>Bezpieczeństwo i higiena pracy</li>
              </ul>
              <h4>Wynagrodzenia</h4>
              <ul>
                <li>
                  Wynagrodzenie ze stosunku pracy i z tytułu umów cywilno-
                  prawnych
                </li>
                <li>
                  Opodatkowanie wynagrodzeń pracowników i z tytułu umów cywilno-
                  prawnych
                </li>
                <li>
                  Zasady podlegania ubezpieczeniom społecznym i zdrowotny
                  pracowników i z tytułu umów cywilno-prawnych
                </li>
                <li>
                  Świadczenia pieniężne z ubezpieczenia społecznego w razie
                  choroby i macierzyństwa
                </li>
                <li>
                  Praktyczne przykłady rozliczeń wynagrodzeń i innych świadczeń
                  dla pracowników
                </li>
                <li>Praktyczne przykłady rozliczeń umów cywilno prawnych</li>
                <li>Wypełnianie formularzy dla ZUS-u</li>
              </ul>
              <h4>Praktyczny aspekt prowadzenia kadr i płac</h4>
              <ul>
                <li>Prowadzenie dokumentacji kadrowej i płacowej</li>
                <li>Obsługa programu kadrowo-płacowego</li>
                <li>Obsługa programu Płatnik</li>
              </ul>
            </TabContent>
            <TabContent index={1}>
              <h3>Harmonogram kursu kadry płace</h3>
              <h5>Zajęcia nr 1</h5>
              <ul>
                <li>przepisy ogólne, przepisy wstępne Kodeksu Pracy,</li>
                <li>podstawowe przepisy prawa pracy,</li>
                <li>równe traktowanie w zatrudnieniu,</li>
                <li>nadzór i kontrola przestrzegania prawa pracy,</li>
                <li>stosunek pracy-akta osobowe,</li>
                <li>stosunek pracy-umowa o pracę,</li>
                <li>dokumenty związane z zatrudnieniem pracowników,</li>
                <li>
                  rozporządzenie Ministra Pracy i Polityki Socjalnej w sprawie
                  zakresu prowadzenia przez pracodawców dokumentacji w sprawach
                  związanych ze stosunkiem pracy oraz sposobu prowadzenia akt
                  osobowych pracownika,
                </li>
                <li>teczki osobowe (części A, B i C),</li>
                <li>umowa o pracę,</li>
                <li>aneks do umowy o pracę.</li>
              </ul>
              <h5>Zajęcia nr 2</h5>
              <ul>
                <li>stosunek pracy – rozwiązanie umowy, (rodzaje),</li>
                <li>stosunek pracy – wygaśnięcie umowy o pracę,</li>
                <li>
                  wykaz prac w szczególnych warunkach i o szczególnym
                  charakterze,
                </li>
                <li>
                  rozporządzenie Ministra Pracy i polityki socjalnej z dnia 15
                  maja 1996 roku w sprawie szczegółowej treści świadectwa pracy
                  oraz sposobu i trybu jego wydawania i prostowania,
                </li>
                <li>Świadectwo pracy,</li>
                <li>Załącznik do świadectwa pracy,</li>
                <li>
                  Rozwiązanie umowy o pracę bez zachowania ustawowego okresu
                  wypowiedzenia (dyscyplinarka lub długotrwała choroba),
                </li>
                <li>Wypowiedzenie zmieniające warunków pracy i płacy,</li>
                <li>
                  Wyliczenie wynagrodzenia netto od brutto (od najniższej
                  krajowej) oraz kosztu zakładu pracy (pracodawcy),
                </li>
                <li>
                  Omówienie składek (wyliczenie) osób fizycznych prowadzących
                  działalność gospodarczą korzystających z 2 letniej ulgi ZUS i
                  nie korzystających z tej ulgi.
                </li>
              </ul>
              <h5>Zajęcia nr 3</h5>
              <ul>
                <li>obowiązki pracodawcy</li>
                <li>obowiązki pracownika</li>
                <li>odpowiedzialność porządkowa pracowników (kary)</li>
                <li>odpowiedzialność materialna pracowników</li>
                <li>
                  odpowiedzialność za wykroczenia przeciwko prawom pracownika
                </li>
                <li>przedawnienie roszczeń</li>
                <li>
                  omówienie programu R2Płatnik, założenie firmy, wprowadzenie
                  danych osoby fizycznej prowadzącej działalność gospodarczą,
                  przyjęcie pracowników (6 na podstawie umowy o pracę, 1 na
                  umowę zlecenie tylko ze składką na ubezpieczenie zdrowotne, 1
                  ze składkami na ubezpieczenia społeczne, zdrowotne, FP i FGŚP
                  i jednego na umowę o dzieło), przygotowanie dokumentów
                  zgłoszeniowych do ZUS pracowników oraz osoby fizycznej
                  prowadzącej działalność gospodarczą z 2 letnią ulgą ZUS lub
                  bez niej (ZUA, ZZA)
                </li>
              </ul>
              <h5>Zajęcia nr 4</h5>
              <ul>
                <li>regulamin pracy</li>
                <li>czas pracy</li>
                <li>normy i ogólny wymiar czasu pracy</li>
                <li>okresy odpoczynku</li>
                <li>systemy i rozkłady czasu pracy</li>
                <li>praca w godzinach nadliczbowych</li>
                <li>praca w porze nocnej</li>
                <li>praca w niedzielę i święta</li>
                <li>
                  urlopy pracownicze (wypoczynkowe i bezpłatne, okolicznościowe,
                  przykłady)
                </li>
                <li>
                  wyliczenie wynagrodzenia urlopowego i ekwiwalentu pieniężnego
                  za niewykorzystany urlop wypoczynkowy (przykłady)
                </li>
                <li>
                  rozporządzenie Ministra Pracy i Polityki Socjalnej w sprawie
                  szczegółowych zasad udzielania urlopu wypoczynkowego,
                  ustalania i wypłacania wynagrodzenia za czas urlopu oraz
                  ekwiwalentu pieniężnego za urlop
                </li>
                <li>usprawiedliwianie nieobecności w pracy</li>
                <li>
                  omówienie programu Płatnik ZUS, założenie firmy, zgłoszenie do
                  ubezpieczeń osoby fizycznej prowadzącej działalność
                  gospodarczą, import dokumentów zgłoszeniowych pracowników i
                  zleceniobiorców oraz zgłoszenie do ZUS
                </li>
              </ul>
              <h5>Zajęcia nr 5</h5>
              <ul>
                <li>wynagrodzenia za pracę i inne świadczenia</li>
                <li>
                  ochrona wynagrodzenia za pracę (kwoty wolne od potrąceń)
                </li>
                <li>
                  uprawnienia pracowników związane z rodzicielstwem
                  (macierzyński, ojcowski, wychowawczy, rodzicielski)
                </li>
                <li>
                  tworzenie list płac z uwzględnieniem urlopów, innych
                  nieobecności usprawiedliwionych i nieusprawiedliwionych
                </li>
              </ul>
              <h5>Zajęcia nr 6</h5>
              <ul>
                <li>
                  wynagrodzenia za pracę i inne świadczenia (wynagrodzenia
                  chorobowe i zasiłki)
                </li>
                <li>
                  oskładkowanie wynagrodzeń chorobowych i zasiłków (przykłady)
                </li>
                <li>
                  wyliczenie wynagrodzeń za czas przepracowany, nieobjęty
                  zwolnieniem lekarskim
                </li>
                <li>
                  wyliczenie podstaw wynagrodzeń chorobowych i zasiłków
                  (praktyczne przykłady)
                </li>
                <li>
                  ustawa o świadczeniach pieniężnych z ubezpieczenia społecznego
                  w razie choroby i macierzyństwa
                </li>
                <li>druk ZUS Z-17, ZUS Z-7</li>
                <li>
                  tworzenie list płac z uwzględnieniem wynagrodzeń chorobowych i
                  różnego rodzaju zasiłków i innych nieobecności
                </li>
              </ul>
              <h5>Zajęcia nr 7</h5>
              <ul>
                <li>omówienie druków zwolnień lekarskich</li>
                <li>ZUS Z-3</li>
                <li>ZUS Z-3b</li>
                <li>ZUS Z-15</li>
                <li>dokumenty do wypłaty zasiłku macierzyńskiego</li>
                <li>dokumenty do zasiłku ojcowskiego</li>
                <li>
                  omówienie wszystkich druków ZUS-owskich na podstawie
                  oryginalnych druków ZUS (ZFA, ZPA, ZIPA, ZUA, ZIUA, ZZA, ZWUA,
                  ZCNA, RSA, RZA, RCA, DRA, RMUA, ZWPA)
                </li>
                <li>
                  przykłady list płac z uwzględnieniem różnego rodzaju
                  nieobecności, godzin nadliczbowych, godzin nocnych
                </li>
              </ul>
              <h5>Zajęcia nr 8</h5>
              <ul>
                <li>
                  omówienie umów cywilnoprawnych na podstawie Kodeksu Cywilnego
                  (umowy o dzieło, umowy zlecenia, podobieństwa i różnice,
                  rachunki do umów, przykłady wyliczeń)
                </li>
                <li>oświadczenie do umowy zlecenie/dzieło</li>
                <li>zbieg tytułów do ubezpieczeń</li>
                <li>pytania kursantów</li>
                <li>ankieta oceniająca szkolenie</li>
                <li>test</li>
                <li>wręczenie certyfikatów</li>
              </ul>
            </TabContent>
          </Tabs>
        </TabsContainer>
      </TextContainer>
    </CourseLayout>
  )
}
